<template>
  <div id="reason" class="service-section">
    <div class="wrap" :class="{ 'wrap-visible': contentVisible }">
      <h2>
        求人のおすすめポイント
        <hr>
      </h2>
      <div class="reason-list">
        <div class="reason">
          <img class="reason-number" src="@/assets/recruit/azuma_kyujin_num001.webp" alt="あずま造園土木が選ばれる理由1つ目">
          <div class="reason-content half">
            <h3>月給50万円も可能！<br class="sp-only">がんばりを給与に反映</h3>
            <p>
              がんばったらそれを給与にしっかりと反映して評価します。月給は25万円からスタートし、最大50万円まで可能です。私たちあずま造園土木は小規模な組織です。仲間となって一緒に仕事をがんばってくれる方にしっかりと給与として評価します。
            </p>
          </div>
        </div>
        <div class="reason left">
          <img class="reason-number" src="@/assets/recruit/azuma_kyujin_num002.webp" alt="あずま造園土木が選ばれる理由2つ目">
          <div class="reason-content half">
            <h3>現場作業だけではない<br class="sp-only">幅広い業務ができる</h3>
            <p>
              あずま造園土木のお仕事は、最初は現場でアルミ製品の組み立てなどから習得して行っていただきますが、その後、お仕事に慣れてきたら、営業や図面作成などステップアップした業務をすることができます。図面と現場が分かるようになれば、現場監督もお願いしていきたいと考えています。もちろん、このようなお仕事のスキルアップも評価します。現場作業だけではない幅広い業務ができることも当社の求人の魅力です。
            </p>
          </div>
        </div>
        <div class="reason">
          <img class="reason-number" src="@/assets/recruit/azuma_kyujin_num003.webp" alt="あずま造園土木が選ばれる理由3つ目">
          <div class="reason-content half">
            <h3>働きやすい人間関係</h3>
            <p>
              言葉で表現することが難しいですが、仕事の中で人間関係で悩むことはありません。小規模でチームワーク良く、働きやすい職場です。お客様にも従業員にも、会社として常に誠実な対応をしています。良い人間関係で長く働ける、そんな職場を目指しています。
            </p>
          </div>
        </div>
        <div class="reason left">
          <img class="reason-number" src="@/assets/recruit/azuma_kyujin_num004.webp" alt="あずま造園土木が選ばれる理由4つ目">
          <div class="reason-content half">
            <h3>経験・資格・年齢は不問</h3>
            <p>
              当社の求人に応募するにあたって、経験・資格・年齢は不問です。経験があればそれはもちろん活かすことができますが、入社の段階では問いません。入社後にお仕事と教育を通して、スキルは身につけることができますので、未経験者でも不安なく働くことができます。
            </p>
          </div>
        </div>
        <div class="reason">
          <img class="reason-number" src="@/assets/recruit/azuma_kyujin_num005.webp" alt="あずま造園土木が選ばれる理由3つ目">
          <div class="reason-content half">
            <h3>柔軟な調整も相談可能</h3>
            <p>
              求人や雇用条件について個別の相談も承ります。いかなる内容でも承れるわけではありませんが、小規模な組織だからこそ柔軟な調整できる場合があります。相談がある場合も、まずはお気軽に相談をください。
            </p>
          </div>
        </div>
      </div>
      <img class="hari008" src="@/assets/recruit/azuma_kyujin_ira.webp" alt="山菜を乗せたハリネズミ">
    </div>
    <!-- スライドイン背景 -->
    <div :class="{ 'slide-in': animate }" class="cover-slider"></div>
  </div>
</template>

<script>
export default {
  name: "ReasonVue",
  data() {
    return {
      animate: false,
      contentVisible: false,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    triggerAnimation() {
      this.animate = true; // アニメーション開始
      const windowWidth = window.innerWidth;
      console.log(windowWidth);
      const setTime = windowWidth <= 767 ? 500 : 1000;
      console.log(setTime);
      setTimeout(() => {
        this.contentVisible = true; // アニメーション完了後にコンテンツを表示
      }, setTime); // アニメーション時間+遅延時間でコンテンツ表示のタイミングを制御
    },
    setupIntersectionObserver() {
      const serviceElement = document.getElementById('reason');
      if (serviceElement) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.triggerAnimation();
              this.observer.unobserve(entry.target);
            } else {
              // no action
            }
          });
        }, {
          root: null,
          threshold: 0.1
        });

        this.observer.observe(serviceElement);
      } else {
        // no action
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.service-section {
  position: relative;
  padding-top: 100px;
  .wrap {
    height: 1250px;
    max-width: 950px;
    position: relative;
    /* コンテンツ アニメーション表示 */
    transition: transform 1.3s ease-out, opacity 1.3s ease-out;
    transform: scale(0.95);
    opacity: 0;
    @media screen and (max-width: 1100px) {
      height: 1400px;
    }
    @media screen and (max-width: 767px) {
      height: 2370px;
    }
    h2 {
      z-index: 3;
      hr {
        z-index: 3;
      }
    }
    .reason-list {
      z-index: 3;
      .reason {
        display: flex;
        justify-content: start;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .reason-number {
          width: 130px;
          height: 130px;
          @media screen and (max-width: 767px) {
            margin: auto;
          }
        }
        .reason-content {
          width: auto;
          h3 {
            font-size: 25px;
            font-weight: 400;
            color: var(--main);
            margin: 10px 0;
            @media screen and (max-width: 767px) {
              font-size: 22px;
              text-align: center;
              line-height: 2rem;
            }
          }
        }
        .half {
          width: 60%;
          @media screen and (max-width: 767px) {
            width: auto;
          }
        }
      }
      .left {
        justify-content: end;
      }
    }
    .hari008 {
      position: absolute;
      bottom: 160px;
      right: 0;
      width: 200px;
      height: 160px;
      @media screen and (max-width: 767px) {
        position: inherit;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 120px;
        margin: auto;
      }
    }
  }
  .wrap-visible {
    /* コンテンツ アニメーション表示 */
    transform: scale(1);
    opacity: 1;
  }
  .cover-slider {
    position: absolute;
    top: 0;
    background-color: var(--sub2);
    width: 87%;
    height: 1350px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transform: translateX(-200%);
    transition: transform 1s ease-out;
    z-index: -1;
    @media screen and (min-width: 1101px) and (max-width: 1350px) {
      width: 100%;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 1500px;
      border-radius: 0;
    }
    @media screen and (max-width: 767px) {
      height:  2470px;
      transition: transform 0.5s ease-out;
    }
  }
  .slide-in {
    transform: translateX(0);
    z-index: -1;
    @media screen and (max-width: 1000px) {
      transform: translateX(0);
    }
  }
}
</style>
