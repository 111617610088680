<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <div class="btn-trigger" @click="hamburgerSet">
        <img
          src="@/assets/top/azuma_top_ham_B.webp"
          alt="ハンバーガーメニュー"
          class="drawer-open"
        />
        <img
          src="@/assets/top/azuma_top_batu.webp"
          alt="ハンバーガーメニュー 閉じる"
          class="drawer-close"
          style="display: none"
        />
      </div>
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <div class="drawer-header"></div>
        <div @click="hamburgerSet" class="drawer-body">
          <div class="drawer-item">
            <div class="drawer-link" @click="top">トップ</div>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/new-exterior">
              新築外構
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/wood-deck">
              ウッドデッキ
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/terrace">
              テラス
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/fence">
              フェンス
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/garden-room">
              ガーデンルーム
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/carport">
              カーポート
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/garage">
              ガレージ
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/exterior-renovation">
              外構リフォーム
            </router-link>
          </div>
          <div class="drawer-item">
            <a class="drawer-link" href="/#contact">お問い合わせ</a>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/recruit">
              求人・採用情報
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="hamburgerSet"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
      active: false,
      open: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
    hamburgerButton() {
      this.active = this.active === false ? true : false;
    },
    imgToggle() {
      const openImg = document.querySelector(".drawer-open");
      const closeImg = document.querySelector(".drawer-close");
      const topScroll = document.querySelector(".top-scroll")
        ? document.querySelector(".top-scroll")
        : null;
      this.open = !this.open;
      if (this.open === true) {
        openImg.style.display = "none";
        closeImg.style.display = "block";
        if (topScroll === null) {
          // no action
        } else {
          topScroll.style.display = "none";
        }
      } else {
        openImg.style.display = "block";
        closeImg.style.display = "none";
        if (topScroll === null) {
          // no action
        } else {
          topScroll.style.display = "block";
        }
      }
    },
    hamburgerSet() {
      this.imgToggle();
      this.switchDrawer();
      this.hamburgerButton();
    },
    top() {
      if (this.$route.path === "/") {
        this.$scrollTo("#app", 500, { easing: "ease" });
      } else {
        //遷移する
        this.$router.push("/");
        this.$scrollTo("#app", 500, { easing: "ease" });
      }
    },
    contact() {
      if (this.$route.path === "/") {
        this.$scrollTo("#contact", 500, { easing: "ease" });
      } else {
        //遷移する
        this.$router.push("/");
        this.$scrollTo("#contact", 500, { easing: "ease" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 280px;
.drawer-wrap {
  .drawer-button {
    width: 50px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 1s 0s ease;
    position: fixed;
    top: 0;
    background-color: rgba(130, 130, 130, 0.8);
    box-sizing: border-box;
    z-index: 999;
    width: $width;
    height: 100vh;

    .drawer-body {
      padding-top: 50px;
      .drawer-item {
        text-align: initial;
        transition: all 1s;
        padding: 12px 10px;
        transition: all 0.5s; //普通のリストは消す
        position: relative; //普通のリストは消す
        z-index: 2; //普通のリストは消す
        &:hover {
          cursor: pointer;
        }
        span {
          margin-left: 10px;
        }

        a,
        .drawer-link {
          width: 100%;
        }

        a,
        span,
        .drawer-link {
          text-decoration: none;
          color: white;
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            bottom: -5px;
            left: 0;
            content: "";
            width: 100%;
            height: 2px;
            background: white;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform 0.5s;
          }
          &:hover::after {
            transform: scale(1, 1);
          }
        }
      }
    }
  }
}

.open-drawer {
  opacity: 1;
  transform: translateX(-$width);
  right: -$width;
}

.close-drawer {
  opacity: 0;
  transition: opacity 3s linear 2s;
  transform: translateX($width);
  right: -$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

.back-gray-show {
  display: initial;
}

.btn-trigger {
  position: relative;
  width: 40px;
  height: 100%;
  cursor: pointer;
  z-index: 1000;
  img {
    margin: auto;
    display: block;
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(20px) rotate(45deg);
  }
  50% {
    transform: translateY(20px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.kakko {
  font-size: 14px;
  margin-left: 5px;
}
</style>
