<template>
  <section>
    <div class="wrap">
      <div class="about-us">
        <div class="about">
          <h1>
            外構工事・エクステリアの
            <br>
            あずま造園土木について
          </h1>
          <p>
            瀬戸市にある株式会社あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに、毎年200件の外構とエクステリアの工事をおこなっています。お客様のご要望をしっかりとお聞きしてそれを叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市です。見積もりは無料です。ぜひお気軽にお問い合わせください。
          </p>
          <img class="pc-only" src="@/assets/top/azuma_top_ira_hari002.webp" alt="黄色のお花">
        </div>
        <div class="house-img">
          <img class="modelroom-img" src="@/assets/top/azuma_top_001.webp" alt="あずま造園土木について">
          <img class="illustration-img pc-only" src="@/assets/top/azuma_top_ira_hari001.webp" alt="左を向くハリネズミ">
        </div>
      </div>

      <div class="construction">
        <img class="house-img" src="@/assets/top/azuma_top_ira001.webp" alt="施工した家のイラストイメージ">
        <div class="constructions">
          <img class="construction-img" src="@/assets/top/azuma_top_002.webp" alt="施工した実際のイメージ">
          <div class="illust">
            <div class="illust-position sp-only">
              <img class="flower-img" src="@/assets/top/azuma_top_ira_hari002.webp" alt="黄色のお花">
              <img class="hedgehog-img" src="@/assets/top/azuma_top_ira_hari001.webp" alt="左を向くハリネズミ">
            </div>
          </div>
        </div>
      </div>

      <div class="news">
        <div class="news-heading">お知らせ</div>
        <div class="news-list">
          <div class="news-content">
            <p class="news-title">
              2024年9月6日
              <span class="pc-only">：</span>
              <span class="sp-only"><br></span>
              <span class="title">求人ページを公開しました。</span>
            </p>
            <p class="news-description">
              求人ページは
              <router-link to="/recruit" class="news-link">こちら</router-link>
              からご確認できます。
            </p>
          </div>
          <div class="news-content">
            <p class="news-title">
              2024年5月13日
              <span class="pc-only">：</span>
              <span class="sp-only"><br></span>
              <span class="title">ホームページリニューアルのお知らせ。</span>
            </p>
            <p class="news-description">ホームページをリニューアルしました。</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUsVue",
}
</script>

<style lang="scss" scoped>
  section {
    padding-bottom: 0;
    .wrap {
      .about-us {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1100px) {
          display: block;
        }
        .about {
          position: relative;
          width: 42%;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
          h1 {
            line-height: 3rem;
            margin-bottom: 30px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              text-align: center;
              line-height: 2rem;
            }
            @media screen and (max-width: 767px) {
              text-align: center;
              line-height: 1.5rem;
              margin-bottom: 20px;
            }
          }
          p {
            @media screen and (max-width: 1100px) {
              margin-bottom: 15px;
            }
          }
          img {
            width: 35%;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        .house-img {
          width: 55%;
          position: relative;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
          .modelroom-img {
            border-radius: 60px;
            @media screen and (max-width: 1100px) {
              border-radius: 0;
            }
          }
          .illustration-img {
            width: 25%;
            position: absolute;
            bottom: -15px;
            right: -100px;
            @media screen and (min-width: 1101px) and (max-width: 1350px) {
              right: -70px;
            }
          }
        }
      }

      .construction {
        position: relative;
        margin-top: 40px;
        @media screen and (max-width: 1100px) {
          display: flex;
          flex-direction: column-reverse;
        }
        .house-img {
          width: 55%;
          @media screen and (max-width: 1100px) {
            width: 100%;
            margin-top: 50px;
          }
        }
        .constructions {
          position: absolute;
          bottom: 0;
          right: -130px;
          width: 39%;
          @media screen and (min-width: 1101px) and (max-width: 1350px) {
            right: 0;
          }
          @media screen and (max-width: 1100px) {
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
          }
          .construction-img {
            border-radius: 60px;
            @media screen and (max-width: 1100px) {
              border-radius: 0;
            }
          }
          .illust {
            width: 100%;
            bottom: -20px;
            left: 0;
            z-index: 1;
            position: relative;
            .flower-img {
              display: block;
              position: absolute;
              bottom: -23px;
              left: 0;
              width: 30%;
              aspect-ratio: 1.5 / 1;
            }
            .hedgehog-img {
              display: block;
              position: absolute;
              bottom: -25px;
              right: 10px;
              width: 25%;
              aspect-ratio: 1 / 1.3;
            }
          }
        }
      }

      .news {
        display: flex;
        justify-content: space-between;
        border-top: 1.5px solid var(--black);
        border-bottom: 1.5px solid var(--black);
        padding: 30px 0;
        margin-top: 80px;
        @media screen and (max-width: 1100px) {
          display: block;
          width: 100%;
          margin-top: 40px;
          padding: 0;
        }
        .news-heading {
          font-size: 22px;
          font-weight: 400;
          color: var(--main);
          width: 20%;
          padding-top: 10px;
          border-right: 1.5px solid var(--black);
          @media screen and (max-width: 1100px) {
            width: 100%;
            text-align: center;
            padding: 15px 0;
            border-right: 0;
            border-bottom: 1.5px solid var(--black);
          }
        }
        .news-list {
          width: 75%;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
          .news-content {
            margin: 10px 0;
            @media screen and (max-width: 1100px) {
              margin: 20px 0;
            }
            .news-title {
              font-size: 20px;
              @media screen and (max-width: 1100px) {
                font-size: 19px;
              }
              .title {
                @media screen and (max-width: 1100px) {
                  color: var(--main);
                }
              }
            }
            .news-description {
              font-size: 16px;
              @media screen and (max-width: 1100px) {
                margin-top: 5px;
                line-height: 1.3rem;
              }
              .news-link {
                color: blue;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>
