<template>
  <section>
    <div class="wrap">
      <div class="message">
        <p>
          瀬戸市に本社があるあずま造園土木では、一緒にお仕事をする仲間を募集しています。小規模な組織で、がんばりをしっかりと給与に反映し、幅広い業務にチャレンジできる環境が整っており、現場作業だけでなく営業や図面作成、現場監督も経験できます。働きやすい人間関係が魅力で、経験・資格・年齢は不問。未経験者でも安心して働ける職場です。
        </p>
        <div class="triangle"></div>
      </div>
      <div class="profile">
        <img src="@/assets/recruit/azuma_kyujin_daihyo.webp" alt="東 岳弘" class="profile-img">
        <p class="profile-text">
          株式会社<br class="sp-only">あずま造園土木<br>代表取締役 東 岳弘
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MessageVue",
}
</script>

<style lang="scss" scoped>
  section {
    padding: 100px 0;
    .wrap {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .message {
        position: relative;
        width: 70%;
        padding: 55px;
        border-radius: 80px;
        margin-bottom: auto;
        color: var(--white);
        background-color: var(--main);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 65%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 20px;
          border-radius: 40px;
        }
        p {
          font-size: 20px;
          line-height: 2.3rem;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 1.8rem;
          }
        }
        .triangle {
          position: absolute;
          top: 100px;
          right: -49px;
          display: inline-block;
          border-left: 50px solid var(--main);
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-right: 0;
          @media screen and (max-width: 767px) {
            top: auto;
            right: auto;
            bottom: -49px;
            left: 80px;
            border-top: 50px solid var(--main);
            border-left: 40px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 0;
            z-index: 2;
          }
        }
      }
      .profile {
        width: 23%;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 25%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          margin-top: 10px;
        }
        .profile-img {
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            margin-top: 25px;
          }
          @media screen and (max-width: 767px) {
            width: 50%;
          }
        }
        .profile-text {
          font-weight: 500;
          line-height: 1.5rem;
          margin-top: 10px;
          text-align: center;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            text-align: center;
          }
          @media screen and (max-width: 767px) {
            width: 50%;
            text-align: start;
            margin-top: auto;
          }
        }
      }
    }
  }
</style>