<template>
  <section>
    <div class="recruit-banner">
      <router-link to="/recruit" class="banner pop">
        <img src="@/assets/recruit/azuma_ba.webp" alt="求人バナー">
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "RecruitBanner",
}
</script>

<style lang="scss" scoped>
  section {
    padding-top: 70px;
    padding-bottom: 70px;
    .recruit-banner {
      display: flex;
      justify-content: center;
      .banner {
        width: 30%;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 40%;
        }
        @media screen and (max-width: 767px) {
          width: 80%;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .pop {
        animation: pop 2s ease-out infinite;
        opacity: 1;
      }
      @keyframes pop {
        0%, 40%, 60%, 80% {
          transform: scale(1.0);
        }
        50%, 70% {
          transform: scale(0.95);
        }
      }
    }
  }
</style>