<template>
  <section>
    <div class="wrap">
      <h2 class="recruit-info-title">募集要項</h2>
      <div class="info-table">
        <div class="info-row">
          <div class="info-title">職種</div>
          <div class="info-content">現場管理・営業・設計・デザイン</div>
        </div>
        <div class="info-row">
          <div class="info-title">仕事内容</div>
          <div class="info-content">
            家の周りの駐車場や門、新築の庭、リフォームの庭の設計、デザイン、現場管理などをしていただきます。<br>
            普段何気なく目にする、おうちの周り（外構）や、店舗施設等の外回りの仕事です。新築の外構工事やお庭のリフォーム、アルミ工事（フェンス、車庫、ウッドデッキ）などの計画やデザインを提案し施工しています。未経験者は、はじめは現場作業のアシスタントとして技術を身につけていただきます。また、意欲がある方には、技術を身につけながらCADによる作図などの勉強をして技術を習得していただきます。使用するCADはRIKCADです。技術の向上による昇給もあります。営業から施工、現場監督のすべての仕事を身につける総合職になります。
          </div>
        </div>
        <div class="info-row">
          <div class="info-title">雇用形態</div>
          <div class="info-content">正社員</div>
        </div>
        <div class="info-row">
          <div class="info-title">
            勤務地<br class="sp-only">
            （本社）
          </div>
          <div class="info-content">〒480-1207<br class="sp-only"> 愛知県瀬戸市品野町1丁目150番地</div>
        </div>
        <div class="info-row">
          <div class="info-title">給与<br class="sp-only">（賞与含む）</div>
          <div class="info-content">月給250,000円～500,000円</div>
        </div>
        <div class="info-row">
          <div class="info-title">昇給</div>
          <div class="info-content">あり</div>
        </div>
        <div class="info-row">
          <div class="info-title">勤務時間</div>
          <div class="info-content">8時～17時30分（休憩90分）</div>
        </div>
        <div class="info-row">
          <div class="info-title">休日</div>
          <div class="info-content">年間100日（週休2日制）</div>
        </div>
        <div class="info-row">
          <div class="info-title">社会保険</div>
          <div class="info-content">完備</div>
        </div>
        <div class="info-row">
          <div class="info-title">応募資格</div>
          <div class="info-content">高卒以上、年齢・経験不問、普通自動車免許</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
  section {
    .wrap {
      .recruit-info-title {
        font-size: 25px;
        font-weight: 500;
        line-height: 5rem;
        color: var(--white);
        background-color: var(--sub1);
        @media screen and (max-width: 767px) {
          line-height: 4rem;
        }
      }
      .info-table {
        .info-row {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--font);
          margin-top: 10px;
          .info-title {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 10px 0;
            border-right: 1px solid var(--font);
            @media screen and (max-width: 767px) {
              width: 35%;
            }
          }
          .info-content {
            width: 80%;
            padding: 10px 30px;
            @media screen and (max-width: 767px) {
              width: 65%;
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 10px 10px 10px 20px;
            }
          }
        }
      }
    }
  }
</style>