<template>
  <section id="contact">
    <h2>
      お見積もり（無料）・お問い合わせ
      <hr>
    </h2>
    <div id="form" class="announce">
      <h3>フォームからのお問い合わせ</h3>
    </div>
    <div class="wrap">
      <p class="annotation">
        フォームからのお問い合わせは24時間受付中です。
        <br class="sp-only">
        返信は平日の9時～19時になります。
        <br>
        求人についてもこちらからお問い合わせをお願いいたします。
      </p>
      <div class="content">
        <div v-if="displayType === '1'">
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お名前</p>
              <div class="hissu">必須</div>
            </div>
            <input
              type="text"
              v-model="formData.name"
              class="form-content"
              placeholder="山田 太郎"
            />
            <p class="error-message" v-if="validation">
              {{ messageData.name }}
            </p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">返信先メールアドレス</p>
              <div class="hissu">必須</div>
            </div>
            <input
              type="email"
              v-model="formData.email"
              class="form-content"
              placeholder="sample@azumazouendoboku.com"
            />
            <p class="error-message" v-if="validation">
              {{ messageData.email }}
            </p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">電話番号</p>
              <div class="hissu">必須</div>
            </div>
            <input
              type="text"
              v-model="formData.tel"
              class="form-content"
              placeholder="0123456789（ハイフンなし）"
            />
            <p class="error-message" v-if="validation">{{ messageData.tel }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お問い合わせ区分</p>
              <div class="hissu">必須</div>
            </div>
            <select
              name="contactType"
              id="contactType"
              class="form-content"
              v-model="formData.contactType"
            >
              <!-- <option value="">選択して下さい</option> -->
              <option value="1">外構工事</option>
              <option value="2">エクステリア</option>
              <option value="3">求人（現在、募集停止中）</option>
              <option value="4">その他</option>
            </select>
            <p class="error-message" v-if="validation">
              {{ messageData.contactType }}
            </p>
          </div>
          <div class="form-row" v-show="this.formData.contactType == 1 ||this.formData.contactType == 2 ">
            <div class="form-text">
              <p class="h-white">工事予定の市町村</p>
              <div class="hissu">必須</div>
            </div>
            <input
              type="text"
              v-model="formData.area"
              class="form-content"
              placeholder="愛知県 瀬戸市"
            />
            <p class="error-message" v-if="validation">
              {{ messageData.area }}
            </p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お問い合わせ内容詳細</p>
              <div class="hissu">必須</div>
            </div>
            <textarea
              name="text"
              id="text"
              v-model="formData.text"
              class="form-content"
              placeholder="お問い合わせ内容を入力してください"
            ></textarea>
            <p class="error-message" v-if="validation">
              {{ messageData.text }}
            </p>
          </div>
          <button @click="checkValidate()">確認</button>
        </div>
        <div v-if="displayType === '2'">
          <h4>入力内容確認</h4>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お名前</p>
            </div>
            <p class="check-content">{{ formData.name }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">返信先メールアドレス</p>
            </div>
            <p class="check-content">{{ formData.email }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">電話番号</p>
            </div>
            <p class="check-content">{{ formData.tel }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お問い合わせ区分</p>
            </div>
            <p class="check-content" v-if="formData.contactType === '1'">
              外構工事
            </p>
            <p class="check-content" v-if="formData.contactType === '2'">
              エクステリア
            </p>
            <p class="check-content" v-if="formData.contactType === '3'">
              求人（現在、募集停止中）
            </p>
            <p class="check-content" v-if="formData.contactType === '4'">
              その他
            </p>
          </div>
          <div class="form-row" v-show="this.formData.contactType == 1 ||this.formData.contactType == 2 ">
            <div class="form-text">
              <p class="h-white">工事予定の市町村</p>
            </div>
            <p class="check-content">{{ formData.area }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">お問い合わせ内容詳細</p>
            </div>
            <textarea
              class="form-content"
              v-model="formData.text"
              readonly
            ></textarea>
          </div>
          <div class="form-button">
            <button @click="back()">戻る</button>
            <button @click="send()">送信</button>
          </div>
        </div>
        <div v-if="displayType === '3'">
          <h4>お問い合わせを承りました</h4>
          <p class="complete-text">
            お問い合わせを承りました。
            お問い合わせ内容を、ご入力のメールアドレスに送信しました。
          </p>
          <p class="complete-text">
            20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
          </p>
          <router-link class="complete-btn" to="/" v-scroll-to="'#app'" @click="formInit()">
            サイトトップへ戻る
          </router-link>
        </div>
      </div>
    </div>
    <div class="announce">
      <h3>お電話でのお問い合わせ</h3>
    </div>
    <div class="wrap">
      <div class="content tell">
        <a href="tel:0561840571" class="phone-number">0561-84-0571</a>
        <div class="business-hours">受付時間：9時～19時（定休日なし）</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ContactVue",
  data() {
    return {
      displayType: "1",
      validation: false,
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      telPattern: /^0\d{9,10}$/,
      formData: {
        name: "",
        email: "",
        tel: "",
        contactType: "1",
        area: "",
        text: "",
      },
      messageData: {
        name: "",
        email: "",
        tel: "",
        contactType: "",
        area: "",
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    checkValidate() {
      // 各入力項目の入力チェック
      this.validation = this.nameValid(this.formData.name);
      this.validation = this.emailValid(this.formData.email);
      this.validation = this.telValid(this.formData.tel);
      this.validation = this.textValid(this.formData.text);
      this.validation = this.areaValid(this.formData.area);
      this.validation = this.contactTypeValid(this.formData.contactType);
      // メッセージの状態チェック
      this.messageCheck();
      // バリデーションがfalseになっているか確認
      if (this.validation !== true) {
        this.displayType = "2";
        this.$scrollTo("#form", 500, { easing: "ease" });
      } else {
        // no action
      }
      this.$scrollTo("#input", 500, { easing: "ease" });
    },
    nameValid(name) {
      if (name !== "") {
        // ok
      } else {
        this.messageData.name = "名前を入力してください";
        return true;
      }

      // 初期化
      this.messageData.name = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    emailValid(email) {
      if (email !== "") {
        if (this.emailPattern.test(email)) {
          // ok
        } else {
          this.messageData.email =
            "メールアドレスは正しい形式で入力してください。";
          return true;
        }
      } else {
        this.messageData.email = "メールアドレスを入力してください";
        return true;
      }

      // 初期化
      this.messageData.email = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    telValid(tel) {
      if (tel !== "") {
        if (this.telPattern.test(tel)) {
          // ok
        } else {
          this.messageData.tel = "正しい電話番号を入力してください";
          return true;
        }
      } else {
        this.messageData.tel = "電話番号を入力してください";
        return true;
      }

      // 初期化
      this.messageData.tel = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    contactTypeValid(contactType) {
      if (contactType !== "") {
        // ok
      } else {
        this.messageData.contactType = "お問い合わせ区分を選択して下さい";
        return true;
      }

      // 初期化
      this.messageData.contactType = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    textValid(text) {
      if (text !== "") {
        // ok
      } else {
        this.messageData.text = "お問い合わせ内容を入力してください";
        return true;
      }

      // 初期化
      this.messageData.text = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    areaValid(area) {
      if (this.formData.contactType == 1 || this.formData.contactType == 2) {
        if (area !== "") {
          // ok
        } else {
          this.messageData.area = "工事予定の市町村を入力してください";
          return true;
        }
      } else {
        this.formData.area = "";
      }

      // 初期化
      this.messageData.area = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    messageCheck() {
      // メッセージが残っていなかったらバリデーションをfalseにする
      for (const key in this.messageData) {
        if (this.messageData[key] === "") {
          // ok
        } else {
          this.validation = true;
          break;
        }
        this.validation = false;
      }
    },
    //確認画面
    back() {
      this.displayType = "1";
      this.$scrollTo("#input", 500, { easing: "ease" });
    },
    // メール送信
    async send() {
      let params = new URLSearchParams();
      // パラメーター追加
      params.append("name", this.formData.name);
      params.append("email", this.formData.email);
      params.append("tel", this.formData.tel);
      params.append("contactType", this.formData.contactType);
      params.append("area", this.formData.area);
      params.append("text", this.formData.text);

      axios
        .post(this.rootUrl.rootUrl + "/api/send.php", params)
        .then((response) => {
          if (response.data.result) {
            this.displayType = '3';
            this.$scrollTo("#form", 500, { easing: "ease" });
            this.formData = {
              name: "",
              email: "",
              tel: "",
              contactType: "1",
              area: "",
              text: "",
            };
          } else {
            // no action
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formInit() {
      this.displayType = "1";
      this.formData = {
        name: "",
        email: "",
        tel: "",
        contactType: "1",
        area: "",
        text: "",
      };
    }
  },
}
</script>

<style lang="scss" scoped>
// todo: お問い合わせ区分のマウスオーバー時に、指マークにする
section {
  .announce {
    padding: 20px 0px;
    margin: 40px 0;
    background-color: var(--main);
    @media screen and (max-width: 767px) {
      margin: 20px 0;
    }
    h3 {
      font-weight: 400;
      text-align: center;
      color: var(--white);
    }
  }
  .wrap {
    margin: 40px auto;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 40px;
    }
    padding: 0;
    .annotation {
      text-align: center;
      color: var(--main);
      margin-bottom: 20px;
    }
    .content {
      background-color: var(--gray);
      h3 {
        line-height: 60px;
        color: var(--white);
        background-color: var(--main);
        margin-bottom: 20px;
        @media screen and (max-width: 1150px) {
          margin-bottom: 10px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      h4 {
        color: var(--font);
        font-size: 20px;
      }
      .form-info {
        text-align: center;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px;
        @media screen and (max-width: 1150px) {
          margin-bottom: 20px;
        }
      }
      .form-row {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
        .form-text {
          display: flex;
          .hissu {
            font-size: 20px;
            line-height: 20px;
            padding: 5px 0;
            text-align: center;
            color: var(--white);
            background-color: var(--main);
            width: 10%;
            @media screen and (max-width: 767px) {
              width: 20%;
            }
          }
          p {
            font-size: 18px;
            font-weight: 400;
            width: 90%;
            color: var(--font);
            @media screen and (max-width: 767px) {
              width: 80%;
            }
          }
        }
        .form-content {
          font-size: 20px;
          width: 100%;
          color: var(--font);
          background-color: var(--white);
          padding: 10px 10px;
          margin-top: 5px;
          border: 1px solid var(--white);
          @media screen and (max-width: 767px) {
            font-size: 16px;
            margin-top: 5px;
          }
        }
        select {
          -webkit-appearance: none;
          height: 45px;
          color: var(--font);
          background-color: var(--white);
          border: 1px solid var(--white);
          cursor: pointer;
          /* 矢印のレイアウト */
          appearance: none;
          background-image: url("@/assets/top/azuma_top_otoiawase.webp");
          background-position: right 20px center;
          background-repeat: no-repeat;
          background-size: 30px 30px;
          &:-ms-expand {
            display: none; /* デフォルトの矢印を非表示(IE用) */
          }
          @media screen and (max-width: 767px) {
            height: 40px;
          }
        }
        .error-message {
          font-size: 18px;
          color: var(--main);
          line-height: 1.5rem;
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
        .check-content {
          font-size: 18px;
          line-height: 26px;
          color: var(--font);
          background-color: var(--white);
          width: 100%;
          height: 46px;
          padding: 10px 10px;
          margin-top: 10px;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 5px;
            height: 44px;
          }
        }
        textarea {
          height: 150px;
          resize: none;
        }
      }
      button,
      .complete-btn {
        display: block;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        padding: 20px 0;
        width: 30%;
        height: 60px;
        cursor: pointer;
        margin: 0 auto;
        color: var(--font);
        background-color: var(--white);
        border: 1px solid var(--white);
        &:hover {
          color: var(--white);
          background-color: var(--main);
          border: 1px solid var(--main);
        }
        @media screen and (max-width: 767px) {
          padding: 10px 0;
          width: 40%;
          height: 50px;
        }
      }
      .form-button {
        display: flex;
        justify-content: space-between;
      }
      .phone-number {
        display: block;
        font-size: 70px;
        line-height: 70px;
        font-weight: 500;
        color: var(--main);
        text-align: center;
        text-decoration: none;
        @media screen and (min-width: 768px) {
          // 現話番号のクリックを無効化する
          pointer-events: none;
          cursor: default;
        }
        @media screen and (max-width: 767px) {
          font-size: 38px;
          line-height: 38px;
          text-wrap: nowrap;
        }
      }
      .business-hours {
        font-size: 25px;
        color: var(--black);
        margin-top: 20px;
        text-align: center;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .complete-text {
        text-align: center;
        @media screen and (max-width: 767px) {
          text-align: start;
        }
      }
      .complete-btn {
        margin-top: 60px;
        @media screen and (max-width: 767px) {
          width: 70%;
        }
      }
    }
    .tell {
      background-color: var(--white);
      padding: 0;
    }
  }
}
</style>
