<template>
  <Top />
  <AboutUs />
  <RecruitBanner />
  <Service />
  <ExampleOfConstruction />
  <Reason />
  <Price />
  <CompanyProfile />
  <CeoProfile />
  <SupportedArea />
  <Flow />
  <Faq />
  <Promise />
  <Contact  />
</template>

<script>
// @ is an alias to /src
import Top from '@/components/Home/Top.vue'
import AboutUs from '@/components/Home/AboutUs.vue'
import RecruitBanner from '@/components/Layout/RecruitBanner.vue'
import Service from '@/components/Home/Service.vue'
import ExampleOfConstruction from '@/components/Home/ExampleOfConstruction.vue'
import Reason from '@/components/Home/Reason.vue'
import Price from '@/components/Home/Price.vue'
import CompanyProfile from '@/components/Home/CompanyProfile.vue'
import CeoProfile from '@/components/Home/CeoProfile.vue'
import SupportedArea from '@/components/Home/SupportedArea.vue'
import Flow from '@/components/Home/Flow.vue'
import Faq from '@/components/Home/Faq.vue'
import Promise from '@/components/Home/Promise.vue'
import Contact from '@/components/Home/Contact.vue'

export default {
  name: 'HomeView',
  components: {
    Top,
    AboutUs,
    RecruitBanner,
    Service,
    ExampleOfConstruction,
    Reason,
    Price,
    CompanyProfile,
    CeoProfile,
    SupportedArea,
    Flow,
    Faq,
    Promise,
    Contact,
  }
}
</script>
