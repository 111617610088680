<template>
  <PageTitle title="ガーデンルームの施工事例" />
  <PageDescription pageName="ガーデンルーム" />
  <div class="wrap page-images">
    <img src="@/assets/garden-room/garden_001.webp" alt="ガーデンルームの施工イメージ画像1" class="sample-img">
    <img src="@/assets/garden-room/garden_002.webp" alt="ガーデンルームの施工イメージ画像2" class="sample-img">
    <img src="@/assets/garden-room/garden_003.webp" alt="ガーデンルームの施工イメージ画像3" class="sample-img">
  </div>
  <RecruitBanner />
  <Reason />
  <Price />
  <ViewMore />
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import PageDescription from '@/components/Ui/PageDescription.vue';
import RecruitBanner from '@/components/Layout/RecruitBanner.vue';
import Reason from '@/components/Home/Reason.vue';
import Price from '@/components/Home/Price.vue';
import ViewMore from '@/components/Ui/ViewMore.vue';

export default {
  name: "GardenRoomVue",
  components: {
    PageTitle,
    PageDescription,
    RecruitBanner,
    Reason,
    Price,
    ViewMore,
  }
}
</script>

<style lang="scss" scoped>
</style>
