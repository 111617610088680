<template>
    <PageTitle title="求人・採用情報" />
    <Message />
    <Reason />
    <RecruitInfomation />
    <Contact />
  </template>
  
  <script>
  import PageTitle from '@/components/Ui/PageTitle.vue';
  import Message from '@/components/Recruit/Message.vue'
  import Reason from '@/components/Recruit/Reason.vue';
  import RecruitInfomation from '@/components/Recruit/RecruitInfomation.vue';
  import Contact from '@/components/Recruit/Contact.vue';
  
  export default {
    name: "RecruitVue",
    components: {
      PageTitle,
      Message,
      Reason,
      RecruitInfomation,
      Contact,
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  